button {
  background-color: #cca43b;
  border: 1px solid #ffffff;
  color: #242f40;
  padding: 0.5em;
}

h2 {
  color: #cca43b;
}

.alignLeft {
  padding-left: 0.25em;
  text-align: left;
}

.alignRight {
  padding-right: 0.25em;
  text-align: right;
}

.App {
  background-color: rgba(36, 47, 64, 0.7);
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  font-weight: bold;
  color: #e5e5e5;
}

.App-link {
  color: #e5e5e5;
}

.btnPositionGallery {
  bottom: 50vh;
  position: fixed;
}

.displayTable {
  display: table;
  text-align: center;
}

.displayTableCell {
  display: table-cell;
}

.fullWidth {
  width: 100%;
}

.iconSize {
  min-height: 1.2em;
  max-height: 2em;
}

.iconWidth {
  min-width: 1.2em;
  max-width: 2em;
}

.inputField {
  background-color: rgba(204, 164, 59, 0.6);
  color: #242f40;
  text-align: center;
}

.inputField:hover {
  background-color: rgba(204, 164, 59, 0.8);
  border: 0.2em solid rgba(36, 47, 64, 1);
  border-radius: 0.2em;
}

.left {
  left: 0.5em;
}

.menuButton {
  background-color: rgba(36, 47, 64, 0.05);
  border: 0.1em solid rgba(204, 164, 59, 0);
  border-radius: 0.2em;
  height: 3em;
  width: 3em;
}

.menuButton:hover {
  background-color: rgba(36, 47, 64, 0.8);
  border: 0.1em solid rgba(204, 164, 59, 1);
}

.noPadding {
  padding: 0 0;
}

.pictureSize {
  max-height: 65vh;
  max-width: 90vw;
}

.positionTopLeft {
  left: 0.5em;
  position: absolute;
  top: 0.5em;
}

.right {
  right: 0.5em;
}

.welcomeContainer {
  margin: 0 auto;
  max-width: 40em;
}

.yellowText {
  color: #cca43b;
}

/*
363636 (dark grey)
e5e5e5 (light grey)
ffffff (white)
cca43b (yellow)
242f40 (dark blue)
*/